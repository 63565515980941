"use strict";

class PropertyListItemComponentController {
    constructor(SSAppService, SSUserService, SSUtilService) {
        const self = this;

        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;
        self.SSUtilService = SSUtilService;

        self.PROPERTY_TYPE_PRIVATE_CONDO = 1;
        self.PROPERTY_TYPE_EXECUTIVE_CONDO = 2;
        self.PROPERTY_TYPE_LANDED = 3;

        self.init();
    }

    init() {
        const self = this;
    }

    click() {
        const self = this;

        self.onClick({property: self.property});
    }

    getPropertyTypeIconClass() {
        const self = this;

        let iconClass = '';

        if (self.property.type == self.PROPERTY_TYPE_PRIVATE_CONDO) {
            iconClass = 'icon-condo';
        } else if (self.property.type == self.PROPERTY_TYPE_EXECUTIVE_CONDO) {
            iconClass = 'icon-ec';
        } else if (self.property.type == self.PROPERTY_TYPE_LANDED) {
            iconClass = 'icon-landed';
        }

        return iconClass;
    }
}

PropertyListItemComponentController.$inject = ['SSAppService', 'SSUserService', 'SSUtilService'];
app.component('propertylistitem', {
    controller: PropertyListItemComponentController,
    template: '' +
    '<div class="row">' +
    '  <div class="project-thumb" ng-click="$ctrl.click()" style="cursor: pointer;">' +
    '    <img ng-src="{{$ctrl.SSAppService.getPropertyHeroThumbUrl($ctrl.property)}}" alt="{{$ctrl.property.name}}">' +
    '  </div>' +
    '  <div class="project-info">' +
    '     <h3 ng-click="$ctrl.click()" style="cursor: pointer;">' +
    '         <i class="icon" ng-class="$ctrl.getPropertyTypeIconClass()" aria-hidden="true"></i>' +
    '         {{$ctrl.property.name}}' +
    '     </h3>' +
    '     <p class="card-text">{{$ctrl.property.location}},' +
    '       <strong>{{$ctrl.property.region}}</strong>' +
    '       <br> Units Balance : {{$ctrl.SSAppService.getRemainingUnits($ctrl.property)}} / {{$ctrl.SSAppService.getTotalUnits($ctrl.property)}}' +
    '       <br> AVG PSF$: {{$ctrl.SSAppService.getFormattedAmount($ctrl.property.psf)}}' +
    '       <br> Tenure: {{$ctrl.property.tenure}}' +
    '       <br ng-show="$ctrl.property.units_last_updated_at"><span ng-show="$ctrl.property.units_last_updated_at" class="last-updated">Last updated: {{ $ctrl.SSUtilService.formatDateDMY($ctrl.property.units_last_updated_at) }}</span>' +
    '     </p>' +
    ' </div>' +
    ' <div class="apartment-share">' +
    '  <loading ng-style="{position: \'absolute\', right: \'60px\', top: \'15px\', width: \'25px\', height: \'25px\'}"' +
    '     ng-show="$ctrl.showLoading" ng-cloak></loading>' +
    '  <ul class="list-unstyled">' +
    '   <li ng-class="$ctrl.SSUserService.isPropertyFavourite($ctrl.property.id) ? \'heart\' : \'\'">' +
    '     <a ng-click="$ctrl.SSUserService.updatePropertyFavorite($ctrl.property.id)">' +
    '     <i class="icon icon-favorite" aria-hidden="true"></i></a>' +
    '   </li>' +
    '   <li class="plus"><a href="javascript:;" ng-click="$ctrl.SSAppService.compareProperty($ctrl.property)"><i class="icon icon-add-to-compare" aria-hidden="true"></i></a></li>' +
    '   <li class="share"><a href="javascript:;" ng-click="$ctrl.SSAppService.getRandomCode($ctrl.property)"><i class="icon icon-share" aria-hidden="true"></i></a></li>' +
    '  </ul>' +
    ' </div>' +
    '</div>',
    bindings: {
        'property': '<',
        'onClick': '&',
        'showLoading': '<'
    }
});